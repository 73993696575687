.detecting-card{
  //width: 327pt !important;
  .detecting-card-header{
    font-weight: bold !important;
    font-size: 1.125rem !important;
    letter-spacing: -0.1125px !important;
    color: #091E3B !important;
    padding-top: 0.75rem !important;
    line-height: 25px !important;
  }
  .detecting-card-data{
    text-align: center !important;
    font-size: 1rem !important;
    line-height: 1.1rem !important;
    letter-spacing: -0.1125px !important;
    color: #091E3B !important;
    font-weight: 300 !important;
    padding: 0.75rem 1.3rem 1.6rem 1.3rem !important;
  } 
  .location-pin{
      width: 50%;
      margin-top: 1.5rem;
    }
}
.ui.dimmer{
  background-color: rgba(0,0,0,0.65) !important;
}
.detecting-area-modal{
  width: 100% !important;
  bottom: 0px !important;
  position: fixed !important;
  height: 18.43rem !important;
 .modal-header{
   font-size: 1.125rem !important;
   font-weight: 600 !important;
   justify-content: center !important;
    padding-top:0px !important ;
    margin-bottom: 2%;
 }
 .modal-content{
  font-size: 90% !important;
  text-align: center;
  width: 100% !important;
 }
 .modal-content-room-floor{
  text-align: center;
  width: 100% !important;
  margin-bottom: 5rem;
  margin-top: 1rem;
  .floor-data{
    font-size: 1rem !important;
    margin-top: 1rem;
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.1px;
  }
 }
 .doubleRing-card {
  margin-right: 4rem;
  align-items: center;
  text-align: center;
  .doubleRing1 {
    height: 60px;
    width: 80px;
    margin-left: 4rem;
    justify-content: center !important;
  }
}
}
.icon-container{
  width: 100%;
}
.location-pin-2{
  width: 20%;
  margin-top: 5%;
 margin-right: 40%;
 margin-left: 40%;
}