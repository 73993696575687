.layer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: white;
  opacity: 0.8;
  top: 0;
  z-index: 11;
  .doubleRing-card {
    margin-right: 3rem;
    align-items: center;
    text-align: center;
    .doubleRing-header {
      width: 301px;
      height: 25px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      color: #091e3b;
      margin-left: 3.5rem;
      margin-bottom: 0.9rem;
    }
    .doubleRing-data-one {
      width: 280px;
      height: 48px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #091e3b;
      margin-left: 3.5rem;
    }
    .doubleRing {
      height: 80px;
      width: 80px;
      margin-left: 4.1rem;
      justify-content: center !important;
    }
  }
}
.animation {
  animation:rotation .8s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
