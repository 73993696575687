@import "~@scuf/styles/variables.scss";

.area-bar {
  height: 2.625rem;
  padding-top: .75rem;
  width: 95% !important;
  display: inline-block;
  color: #405B81;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.09375px;
}
.area-divider {
  margin: 0 !important;
  width: 100% !important;
}
.dropdown-area-divider {
  margin-top: 0% !important;
  width: 100% !important;
  margin-bottom: 0% !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.65) !important;
}
.area-modal {
  width: 100% !important;
  bottom: 0px !important;
  position: fixed !important;
  padding-top: 3% !important;
  padding-left: 3% !important;
  padding-right: 3% !important;
  padding-bottom: 0 !important;
  max-height: 80vh;

  .ui.divider:last-of-type {
    display: none;
  }  
  .icon-pin {
    height: fit-content;
  }
  .modal-header {
    font-size: 110% !important;
    font-weight: 700 !important;
    justify-content: center !important;
  }
  .modal-content {
    font-size: 90% !important;
  }
  .modal-div{
    width: 100%;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
  }
}
.area-caret {
  width: 5% !important;
  display: inline-block;
}
.selected-floor-title {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.09375px;  
  color: #0072EF;
  .back-icon{
    margin-right: 0.4rem;
    float: left;
    .icon-image{
      width: 3.5rem;
      height: 4rem;
      border-radius: 3rem;
    }
    }
  .floor-name{
    padding-top:1.35rem;
    color: #0072EF;
  }
  .floor-name-no-link {
    color:  #000000;
    padding: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
}
.search {
  position: relative;
  margin-left: 1rem;
  width: 100%;
  .search-input {
    width: 100%;
    padding-right: 1rem !important;
    .input-wrap {
      width: 100%;
      .input-box {
        width: 100%;
        background: #f3f5f7;
        border-radius: 6px;
        color: #99A1AA;
        height: 44px;
        border: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .search-icon {
    position: absolute;
    top: 2.5rem;
    right: 2rem;
    z-index: 999;
    color: #2c4872;
    .h-icon.common{
      font-size: 0.9rem !important;
      font-weight: 900;
      color: #091e3b;
    }
  }
}

.no-results {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  &-header {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    color: #091e3b;
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #091e3b;
  }
}
.floor-name-caret {
  align-content: center;
}
.not-detecting-card {
  width: 88% !important;
  margin: auto !important;
  margin-top: 2rem !important;
  .not-detecting-card-header {
    font-weight: bold !important;
    font-size: 106% !important;
    align-self: center;
    margin-bottom: 3% !important;
  }
  .not-detecting-card-data {
    font-size: 16px !important;
    text-align: center !important;
    margin-bottom: 5% !important;
  }
  margin-bottom: 5% !important;
  max-height: 75vh;
  overflow: auto;
  .mts {
    margin-top: 2em;
  }
}
.no-beacon-detecting-card {
  width: 88% !important;
  margin: auto !important;
  margin-top: 2rem !important;
  .no-beacon-detecting-card-header {
    font-weight: bold !important;
    font-size: 80% !important;
  }
  .no-beacon-detecting-card-data {
    font-size: 80% !important;
    text-align: center !important;
    margin-bottom: 5% !important;
  }
}
.margin-2 {
  margin-bottom: 1.5rem;
}
.doubleRing-card {
  margin-right: 3rem;
  align-items: center;
  text-align: center;
  .doubleRing {
    height: 50px;
    width: 50px;
    margin-top: 3rem;
    margin-bottom: 6rem;
    margin-left: 0rem;
    justify-content: center !important;
  }
  .doubleRing1 {
    height: 50px;
    width: 50px;
    margin-top: 1.5rem;
    margin-left: 4rem;
    justify-content: center !important;
  }
}
.sort-room-list {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 1rem !important;
  padding-bottom: 0.75rem;
  .sort-list {
    position: absolute;
    // display: flex;
    border-radius: 8px;
    margin-top: 16px;
    top: 40px;
    right: 2px;
    box-shadow: 0 10px 20px #e7eaee;
    background: rgba(255, 255, 255, 1);
    .text-wrap {
      display: flex;
      padding: 11.5px 16px;
      align-items: center;
      border-bottom: 1px solid #E7EAEE;
    }
    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #091e3b;
      margin-bottom: 0 !important;
    }
    &-icon {
      margin-top: 0.4rem !important;
      margin-left: 1.5rem !important;
    }
    &-up-icon {
      margin-top: 0.3rem !important;
    }
  }
  .room-list-text {
    padding-top: 1rem;
    color: #597091;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
  }
  .room-sorting {
    display: flex;
    .sort-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #0072ef;
      margin-right: 12px;
      margin-bottom: 0;
      align-items: center;
      display: flex;
    }

  }
  .dropdown-input {
    max-height: 1rem;
    width: 52% !important;
    margin-left: 1.3rem;
    z-index: 1;
  }
  .ui.multiple.selection.dropdown {
    border: none !important;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .ui.active.visible.selection.multiple.dropdown {
    border: none;
    box-shadow: none;
  }
  .ui.scuf-dropdown-wrapper > .ui.selection.dropdown > .default.text,
  .ui.scuf-dropdown-wrapper > .ui.multiple.selection.dropdown > .default.text,
  .ui.scuf-dropdown-wrapper > .ui.dropdown.disabled > .default.text {
    z-index: 2;
    font-size: 14px !important;
    color: #0072ef !important;
    margin-left: 6.8rem !important;
    font-style: normal;
  }
  .ui.active.visible.selection.multiple.dropdown .visible.menu.transition {
    border: none !important;
    // box-shadow: none !important;
  }
  .ui.active.visible.selection.multiple.dropdown .selected.item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
  }
  .ui.scuf-dropdown-wrapper > .ui.selection.dropdown .menu > .item i.icon,
  .ui.scuf-dropdown-wrapper
    > .ui.multiple.selection.dropdown
    .menu
    > .item
    i.icon,
  .ui.scuf-dropdown-wrapper > .ui.dropdown.disabled .menu > .item i.icon {
    visibility: visible !important;
    color: #0072ef !important;
  }
  .chevron.down.icon {
    visibility: hidden !important;
    z-index: 1;
  }
  .arrow-icon {
    margin-top: 0.8rem;
    color: #0072ef !important;
    height: 13px !important;
    width: 13px !important;
    font-size: 0.7rem !important;
    font-weight: 600 !important;
  }
}

.near-room-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #597091;
}
.near-room-radius{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #091E3B;
  margin-left: 3rem !important;
  padding-top: 2px;
}

.near-room{
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000; 
  margin-left: 3rem;
}

.near-room-icon{
  display:inline;
  float: left;
  width: 32px;
  height: 32px;
  background: #F3F3F5;
  border-radius: 6px;
}

.near-room-container{
  margin-top: 1.25rem;
}

.near-room-sub-container{
  margin-top: 0.75rem;
}

.ui.modal .scrolling.content{
  max-height: calc(60vh) !important;
  padding-top: 0 !important;
  padding-bottom: 2.5rem !important;
}

.api-fail-container{
  padding: 2rem;
  .api-fail-data{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #091E3B;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
  .api-fail-link{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.0875px;
    color: #0334E2;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
  }
}

.card-content{
  padding-left: 0px !important;
}
.all-floor{
  padding-left: 1.5rem;
  .ui.divider:last-of-type {
    display: none;
  } 
}
.list-of-floor{
  .ui.divider:last-of-type {
    display: none;
  } 
}
