
.sample-page-container {
    height: 100%;
}
.sample-page {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 80%;
    padding: 10px;

    h1 {
        padding-bottom: 18px;
    }
}