:root {
    --font-type: "SFPro";
}

body,
html {
font-family: var(--font-type) !important;
}

.ui.card {
    font-family: var(--font-type) !important;
    .header {
        font-family: var(--font-type) !important;
    }
    .meta {
        font-family: var(--font-type) !important;
    }
    .content {
        font-family: var(--font-type) !important;
    }
}

.ui.modal {
    .content {
        font-family: var(--font-type) !important;
    }
}




@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SFPro";
    src: local("SFPro"),
        url("./fonts/SFPro/SFProText-Regular.ttf") format("truetype");
}