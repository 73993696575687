@import "~@scuf/styles/variables.scss";
$duration: 1.0s;

.content-center-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.temperature-light-control-container{
   background-color:#f9f9fab7;
   width: 100% !important;
   height: 100% !important;
}
.no-controls{
  width: 100% ;
  margin-top: 20%;
  text-align: center !important;
}

.header-card, .detecting-card{
  margin: 5% 5% 5% 5.8% !important;
  align-items: center !important;
  width:88% !important;
  padding-bottom: 1% !important;
  border: 1px !important;
  border-radius: 16px !important;
  .header-title{
    font-size: 1.125rem !important;
    text-align: center !important;
    font-weight: 600 !important;
    padding-top: 1rem !important;
  }
  .header-link{
    text-align: center !important;    
    letter-spacing: -0.0875px !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    .meta-content{
    font-size: 1.125rem !important;
    line-height: 25px;
    }
    a {
      color: #0334E2 !important;
    }
    .blueButton{
      font-size: 87.5% !important;
      font-weight: bold !important;
      padding-bottom: 150% !important;
    }

  }
}


.detecting-area-modal{
  width: 100% !important;
  bottom: 0px !important;
  position: fixed !important;
 
 .modal-header{
   font-size: 100% !important;
   font-weight: 700 !important;
   justify-content: center !important;
    padding-top:0px !important ;
    margin-bottom: 2%;
 }
 .modal-content{
  font-size: 90% !important;
  text-align: center;
  width: 100% !important;
 }
}

.location-pin-2{
  width: 20%;
  margin-top: 5%;
 margin-right: 40%;
 margin-left: 40%;
}

.icon-container{
  width: 100%;
}

.temp-card{
//  padding:25px 0px 40px 0px;
margin-bottom: 1.5rem;
  .disableControl{
    pointer-events:none;
  }
}
.line{
  width:88% !important;  
  border: 1px solid #DEE2E8;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.blueButton{
  padding: 5px 27px 5px 27px;
  border-radius: 6px;
  background: #429CFF;
  border: 1px solid #429CFF;
  color: white;
  min-height: 34px;
}

.mtm{
  margin-top: 12px;
}

.mbm{
  margin-bottom: 12px;
}

.mbl{
  margin-bottom: 24px;
}

#modal{
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

#card{
  border-radius: 10px !important;
}
 .see-another-room{
color: #0334E2 !important;
 }

.current-value {
  font-size: 14px;
}
.lt-1 {
  transform: rotate(10deg)!important;
  margin-left: 20px !important;
}
.lt-2 {
  transform: rotate(-11deg)!important;
  margin-left: 20px !important;
}
.lt-3 {
  transform: rotate(-33deg)!important;
  margin-left: 20px !important;
}
.lt-4 {
  margin-left: 21px!important;
  transform: rotate(-50deg)!important;
}
.lt-5 {
  margin-left: 21px!important;
  transform: rotate(-70deg)!important;
}
.lt-6 {
  margin-left: 26px!important;
  transform: rotate(268deg)!important;
}
.lt-7 {
  margin-left: 26px!important;
  transform: rotate(250deg)!important;
}
.lt-8 {
  transform: rotate(230deg)!important;
  margin-top: -10px!important;
  margin-left: 30px!important;
}
.lt-9 {
  transform: rotate(210deg) !important;
  margin-left: 30px !important;
  margin-top: -25px !important;
}
.lt-10 {
  transform: rotate(190deg) !important;
  margin-left: 30px !important;
  margin-top: -25px !important;
}
.lt-11 {
  transform: rotate(170deg)!important;
  margin-left: 25px!important;
  margin-top: -30px!important;
}