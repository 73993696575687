.detecting-card{
  .error-card-header{
    font-weight: bold !important;
    font-size: 18px !important;
    letter-spacing: -0.1125px !important;
    line-height: normal !important;
    margin-top: 1rem;
    color: #091E3B !important;
  }
  .error-card-data{
    font-size: 16px !important;
    text-align: center !important;
    margin-bottom: 1.25rem!important;
    margin-top: 0.75rem;
    line-height: 1.375rem !important;
    color: #000000 !important;
    letter-spacing: -0.1125px !important;
  } 
  .error-pin{
      height: 100px;
      width: 100px;
      margin-top: 1.5rem;
    }
}
.ui.dimmer{
  background-color: rgba(0,0,0,0.65) !important;
}
.icon-container{
  width: 100%;
}
.location-pin-2{
  width: 20%;
  margin-top: 10%;
 margin-right: 40%;
 margin-left: 40%;
 margin-bottom: 5%;
}
.retry-button{
  width: 88%;
  border-color: #429CFF !important;
  border-radius: 6px !important;
  background-color: #429CFF !important;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 5% !important;
  font-size: 1.125rem !important;
  height: 2.75rem;
  line-height: 0 !important;
}
.room-detection-failure {
  display: flex;
  flex-flow: column;
  gap: 1.3rem;
  align-items: center;
  padding: 2rem;
  .room-detection-failure-icon {
    width: 56px;
    height: 56px;
  }
  .room-detection-failure-title {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #091E3B;
    font-weight: bold;
  }
  .room-detection-failure-desc {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    gap: 1.3rem;
    color: #091E3B;
  }
  .try-again-button{
    width: fit-content !important;
    border-radius: 6px !important;
    background-color: #429CFF !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1rem !important;
    height: 2.25rem;
    line-height: 0 !important;
    letter-spacing: normal !important;
    padding: 0% 5% !important;
}
}