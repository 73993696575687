.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: transparent;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 5px;
  z-index: 2;
  position: absolute;
  left: 0px;
}


.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #429CFF;
  cursor: pointer;
  border-radius: 50%;
  border:4px solid white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}
.wireless::-webkit-slider-thumb {
  background: #704BEF !important;
}

.container{
  display: flex;
  align-items: center;
  background-color: #DEE2E8;
  height: 18px;
  border-radius: 10px;
}
.value{  
  position: absolute;
  background-color: #429cff;
  height: 20px;
  border-radius: 10px;
  z-index: 1;
  flex: 1;
  transition: color 0.3s;
}
.progress{  
  position: absolute;
  background-color: #704bef;
  height: 20px;
  border-radius: 10px;
  z-index: 1;
  flex: 1;
  transition: color 0.3s;
}
.flex{
  display: flex;
}
.row{
  flex-direction: row;
}
.column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
.align-start{
  align-items: flex-start;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.minMaxValue{
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
}

.medium-text{
  font-size: 12px;
}
.large-text{
  font-size: 28px;
}
.bold-600{
  font-weight: 600;
}
.mts
{
  margin-top:10px;
}
.mtm{
  margin-top:20px;
}
.purple{
  color: #704BEF;
}
.red{
  color:rgb(254,25,25);
}
.min-height-24{
  min-height: 24px;
}
.pls{
  padding-left: 5px;
}

.unavailable-header-card{
  width: 100%;
  align-items: center !important;
  .unavailable-header-title{
    text-align: center !important;
    font-size: 80% !important;
    font-weight: 600 !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    letter-spacing: -0.075px !important;
    line-height: 1rem !important;
  }
  .unavailable-header-link{
    padding-top: 0.5rem !important;
    margin-top: 0 !important;
    font-size: 90% !important;
    padding-bottom: 0.7rem !important;
    text-align: center !important;
    a {
      color: #0334E2;
    }
  }
  .temp-null-msg{
    font-weight: 400;
    color: #C0C0C0;
    text-align: center !important;
    font-size: 75% !important;
    letter-spacing: -0.075px !important;
    line-height: 0.75rem !important;
    white-space: pre-wrap !important;
    padding-top: 0.15rem !important;
    margin-bottom: 0.2rem !important;
  }
}
.underlay {
  position: relative;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  border-radius: 10px;
  border: 1px solid #429CFF;
}

.breakpoint {
  width: Calc(100% / 3);
  background-color: #DEE2E8;
}

// .breakpoint:not(:first-child) {
//   border-left: 1px solid #429CFF;

// }
.breakpoint:not(:first-child):before {
  content: '';
  position: relative;
  border-left: 1.5px solid #429CFF;
  bottom: 7px;
}