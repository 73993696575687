h1,

#appearance1 .rs-border {
  border-color: #DEE2E8;
  fill: #DEE2E8;
  stroke: none;
}
#appearance0 .rs-border {
  border-color: #DEE2E8;
  fill: #DEE2E8;
  stroke: none;
}
#appearance-disabled .rs-border {
  border-color: #DEE2E8;
  fill: #DEE2E8;
  stroke: none;
}

#appearance2 .rs-handle {
  background-color: #39bbff;
  border: 0px;
}
.blue{
  height: 6px !important;
  width: 30px !important;
  margin:-8px 0px 0px -5px !important;
}

.orange{
  height: 6px !important;
  width: 30px !important;
  margin:2px 0px 0px -5px !important;
}

#appearance0 .rs-handle-dot:after {
  background-color: #3f7cfa;
  border: 0px;
}

#appearance0 .rs-handle-dot{
  border: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}

#appearance1 .rs-handle-dot{
  border: 0px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25)
}

#appearance-disabled .rs-handle-dot{
  border: 0px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25)
}

#appearance1 .rs-handle-dot:after {
  background-color: #ed7310;
  border: 0px;
}

#appearance-disabled .rs-handle-dot:after {
  background-color: #BBBBBB;
  border: 0px;
}

.temp-text{
  font-weight: 500;
  position:absolute;
  top:91px;
  font-size:medium;
}

.temp-value{
  margin-top: 0px;
  font-weight: 500;
  font-size: 35px;
}

.mt-45 {
  margin-top: 65px;
}

.error-msg {
  color: red;
  font-size: 12px;
  padding: 25px 30px 30px 30px;
}

.temp-error{
  color:red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: x-small;
  position:absolute;
  bottom:50px;
}

.display-block {
  display: block;
}
.display-none {
  display: none;
}

.min{
  font-size: small;  
}
.max{
  font-size: small;  
}

.icon{
  padding:0px 10px;
}

.slider-value-on-path-orange{
  transform: rotate(270deg);
  width: 25px;
  margin: 6px 0px 0px -3px;
}

.slider-value-on-path-blue{
  transform: rotate(277deg);
  width: 25px;
  margin: -37px 0px 0px -3px;
}
.invisible {
visibility: hidden;
}

.action-buttons-container{
   width: 345px;
   margin-top: -55px;
   z-index: 7;
   .parent{ 
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin-left: 34px 
  }
}