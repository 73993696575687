.restricted-room {
    display: flex;
    flex-flow: column;
    gap: 1.3rem;
    align-items: center;
    padding: 2rem;
    .restricted-room-icon {
        width: 56px;
        height: 56px;
    }
    .restricted-room-title {
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #091E3B;
        font-weight: bold;
    }
    .restricted-room-desc {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
        gap: 1.3rem;
        color: #091E3B;
        .section-one {
            .section-one-roomname {
                font-weight: bold;
            }
        }
    }
}